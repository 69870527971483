import React, { Component } from 'react';
import firebase from 'firebase';
import { Link } from 'react-router-dom';
import { Button, Container, Table } from 'semantic-ui-react';

const timestampToDate = timestamp => {
  const date = new Date(timestamp.seconds * 1000);
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const month = months[date.getMonth()];
  return `${month} ${date.getDate()}, ${date.getFullYear()}`;
};

const Notice = props => {
  const { notices } = props;
  return (
    <Container text>
      <Table basic="very" selectable>
        <Table.Header>
          <Table.HeaderCell width={12} content="Title" />
          <Table.HeaderCell width={4} content="Date" />
        </Table.Header>
        <Table.Body>
          {notices.map(notice => (
            <Table.Row>
              <Table.Cell>
                <Link to={`/notice/${notice.id}`}>{notice.title}</Link>
              </Table.Cell>
              <Table.Cell content={timestampToDate(notice.publishedAt)} />
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {/* <Button primary icon="add" content="New notice" style={{ float: 'right' }} /> */}
    </Container>
  );
};

class NoticeContainer extends Component {
  state = {
    notices: []
  };
  
  async componentWillMount() {
    const snapshot = await firebase.firestore().collection("notices").get();
    const notices = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
    this.setState({ notices });
  }

  render() {
    const { notices } = this.state;
    return <Notice notices={notices} />;
  }
}

export default NoticeContainer;
