import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase';
import { Container, Header } from 'semantic-ui-react';

const propTypes = {
  match: PropTypes.shape({}).isRequired,
};

class NoticeDetail extends Component {
  state = {
    notice: {},
  };

  async componentWillMount() {
    const { match } = this.props;
    const { id } = match.params;
    const doc = await firebase.firestore().collection('notices').doc(id).get();
    const notice = doc.data();
    this.setState({ notice });
  }

  render() {
    const { notice } = this.state;
    return (
      <Container text>
        <Header as="h1" dividing content={notice.title}/>
        <p>{notice.content}</p>
      </Container>
    )
  }
}

NoticeDetail.propTypes = propTypes;

export default NoticeDetail;
