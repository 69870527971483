import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import Home from './components/Home';
import Login from './components/Login';
import Profile from './components/Profile';
import NavBar from './components/NavBar';
import EventList from './components/EventList';
import EventRegistration from './components/EventRegistration';
import Notice from './components/Notice';
import NoticeDetail from './components/NoticeDetail';

const propTypes = {
  location: PropTypes.shape({}).isRequired,
};

const App = (props) => {
  const { location } = props;
  return (
    <div>
      <div className="page">
        <NavBar pathname={location.pathname} />
        <Switch>
          <Route exact path="/" component={Notice} />
          <Route exact path="/login/" component={Login} />
          <Route exact path="/profile/" component={Profile} />
          <Route exact path="/notice/:id/" component={NoticeDetail} />
          <Route exact path="/events/" component={EventList} />
          <Route exact path="/events/:id/" component={EventRegistration} />
          <Redirect from="/" to="/" />
        </Switch>
      </div>
    </div>
  );
};

App.propTypes = propTypes;

export default withRouter(App);
