import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase';
import { Button, Container, Header, Segment, Input, Form, Dimmer, Loader, Select } from 'semantic-ui-react';
import _ from 'lodash';

const propTypes = {
  match: PropTypes.shape({}).isRequired,
};

const genderOptions = [
  { text: 'Male', value: 'M' },
  { text: 'Female', value: 'F' },
  { text: 'Others', value: 'O' },
];

const TeamLeaderCard = props => (
  <Segment>
    <Form>
      <Header as="h4">Person #{props.index + 1}</Header>
      <Form.Group>
        <Form.Input width={4} name="givenName" label="Given Name" value={props.givenName} onChange={props.onChange} />
        <Form.Input width={4} name="surname" label="Surname" value={props.surname} onChange={props.onChange} />
        <Form.Input width={8} name="fullName" label="Displayed Name" value={props.fullName} onChange={props.onChange} />
      </Form.Group>
      <Form.Group>
        <Form.Input width={8} name="nationality" label="Nationality" value={props.nationality} onChange={props.onChange} />
        <Form.Input width={8} name="passportNumber" label="Passport Number" value={props.passportNumber} onChange={props.onChange} />
      </Form.Group>
      <Form.Group>
        <Form.Input width={8} type="email" name="email" label="Email Address" value={props.email} onChange={props.onChange} />
        <Form.Input width={8} name="phone" label="Phone Number" value={props.phone} onChange={props.onChange} />
      </Form.Group>
      <Form.Group>
        <Form.Input width={8} name="dateOfBirth" label="Date of Birth" value={props.dateOfBirth} onChange={props.onChange} />
        <Form.Select
          width={4} name="genderOfficial" label="Gender (as on passport)"
          value={props.genderOfficial} onChange={props.onChange} options={genderOptions}
        />
        <Form.Input width={4} name="genderPersonal" label="Gender (if different from passport)" value={props.genderPersonal} onChange={props.onChange} />
      </Form.Group>
      <Form.Input width={16} name="specialNeeds" label="Special Needs" value={props.specialNeeds} onChange={props.onChange} />
      <Form.Input width={16} name="dietary" label="Dietary Requirements" value={props.dietary} onChange={props.onChange} />
      <Form.Input width={16} name="arrivalInfo" label="Arrival Information" value={props.arrivalInfo} onChange={props.onChange} />
      <Form.Input width={16} name="departureInfo" label="Departure Information" value={props.departureInfo} onChange={props.onChange} />
      <Form.Input width={16} name="notes" label="Notes" value={props.notes} onChange={props.onChange} />
    </Form>
    <div style={{ marginTop: 20 }}>
      <Button negative content="Delete" onClick={props.onDelete} />
    </div>
  </Segment>
);

const ObserverCard = props => (
  <Segment>
    <Form>
      <Header as="h4">Person #{props.index + 1}</Header>
      <Form.Group>
        <Form.Input width={4} name="givenName" label="Given Name" value={props.givenName} onChange={props.onChange} />
        <Form.Input width={4} name="surname" label="Surname" value={props.surname} onChange={props.onChange} />
        <Form.Input width={8} name="fullName" label="Displayed Name" value={props.fullName} onChange={props.onChange} />
      </Form.Group>
      <Form.Group>
        <Form.Input width={8} name="nationality" label="Nationality" value={props.nationality} onChange={props.onChange} />
        <Form.Input width={8} name="passportNumber" label="Passport Number" value={props.passportNumber} onChange={props.onChange} />
      </Form.Group>
      <Form.Group>
        <Form.Input width={8} type="email" name="email" label="Email Address" value={props.email} onChange={props.onChange} />
        <Form.Input width={8} name="phone" label="Phone Number" value={props.phone} onChange={props.onChange} />
      </Form.Group>
      <Form.Group>
        <Form.Input width={8} name="dateOfBirth" label="Date of Birth" value={props.dateOfBirth} onChange={props.onChange} />
        <Form.Select
          width={4} name="genderOfficial" label="Gender (as on passport)"
          value={props.genderOfficial} onChange={props.onChange} options={genderOptions}
        />
        <Form.Input width={4} name="genderPersonal" label="Gender (if different from passport)" value={props.genderPersonal} onChange={props.onChange} />
      </Form.Group>
      <Form.Input width={16} name="specialNeeds" label="Special Needs" value={props.specialNeeds} onChange={props.onChange} />
      <Form.Input width={16} name="dietary" label="Dietary Requirements" value={props.dietary} onChange={props.onChange} />
      <Form.Input width={16} name="arrivalInfo" label="Arrival Information" value={props.arrivalInfo} onChange={props.onChange} />
      <Form.Input width={16} name="departureInfo" label="Departure Information" value={props.departureInfo} onChange={props.onChange} />
      <Form.Input width={16} name="notes" label="Notes" value={props.notes} onChange={props.onChange} />
    </Form>
    <div style={{ marginTop: 20 }}>
      <Button negative content="Delete" onClick={props.onDelete} />
    </div>
  </Segment>
);

const ContestantCard = props => (
  <Segment>
    <Form>
      <Header as="h4">Person #{props.index + 1}</Header>
      <Form.Group>
        <Form.Input width={4} name="givenName" label="Given Name" value={props.givenName} onChange={props.onChange} />
        <Form.Input width={4} name="surname" label="Surname" value={props.surname} onChange={props.onChange} />
        <Form.Input width={8} name="fullName" label="Displayed Name" value={props.fullName} onChange={props.onChange} />
      </Form.Group>
      <Form.Group>
        <Form.Input width={8} name="nationality" label="Nationality" value={props.nationality} onChange={props.onChange} />
        <Form.Input width={8} name="passportNumber" label="Passport Number" value={props.passportNumber} onChange={props.onChange} />
      </Form.Group>
      <Form.Input width={16} name="workingLanguage" label="Working Language" value={props.workingLanguage} onChange={props.onChange} />
      <Form.Group>
        <Form.Input width={8} name="dateOfBirth" label="Date of Birth" value={props.dateOfBirth} onChange={props.onChange} />
        <Form.Select
          width={4} name="genderOfficial" label="Gender (as on passport)"
          value={props.genderOfficial} onChange={props.onChange} options={genderOptions}
        />
        <Form.Input width={4} name="genderPersonal" label="Gender (if different from passport)" value={props.genderPersonal} onChange={props.onChange} />
      </Form.Group>
      <Form.Input width={16} name="specialNeeds" label="Special Needs" value={props.specialNeeds} onChange={props.onChange} />
      <Form.Input width={16} name="dietary" label="Dietary Requirements" value={props.dietary} onChange={props.onChange} />
      <Form.Input width={16} name="arrivalInfo" label="Arrival Information" value={props.arrivalInfo} onChange={props.onChange} />
      <Form.Input width={16} name="departureInfo" label="Departure Information" value={props.departureInfo} onChange={props.onChange} />
      <Form.Input width={16} name="notes" label="Notes" value={props.notes} onChange={props.onChange} />
    </Form>
    <div style={{ marginTop: 20 }}>
      <Button negative content="Delete" onClick={props.onDelete} />
    </div>
  </Segment>
);

class EventRegistration extends Component {
  state = {
    isLoading: true,
    country: {},
    numTeamLeaders: 0,
    numContestants: 0,
    numObservers: 0,
    teamLeaders: [],
    teams: [],
    observers: [],
  };

  constructor() {
    super();
    firebase.auth().onAuthStateChanged(this.onAuthStateChanged)
  }

  onAuthStateChanged = async (firebaseUser) => {
    if (firebaseUser == null) {
      window.location.replace("/login/");
      return;
    }
    try {
      console.log('firebaseUser', firebaseUser);
      const { uid } = firebaseUser;
      const user = (await firebase.firestore().collection('users').doc(uid).get()).data();
      if (user == null || user.confirmedNationality == null) {
        alert("Your nationality is not confirmed yet. Please fill in your profile and wait for confirmation.");
        this.setState({ isLoading: false });
        return;
      }
      const { confirmedNationality } = user;
      const application = (await firebase.firestore().collection('applications').doc(confirmedNationality).get()).data();
      this.setState({ isLoading: false, ...application });
    } catch (error) {
      alert(error);
    }
  }

  onChange = (e, { name, value }) => this.setState({ [name]: value });
  onSave = async () => {
    const { country } = this.state;
    try {
      await firebase.firestore().collection("applications").doc(country.code).set(this.state);
      alert("Your application is saved.");
    } catch (error) {
      alert(error);
    }
  }

  onAddTeamLeader = () => {
    let { teamLeaders } = this.state;
    teamLeaders.push({});
    this.setState({ teamLeaders });
  };
  onAddTeam = () => {
    let { teams } = this.state;
    teams.push({});
    this.setState({ teams });
  }
  onAddObserver = () => {
    let { observers } = this.state;
    observers.push({});
    this.setState({ observers });
  };
  onAddContestant = (teamIndex) => {
    let { teams } = this.state;
    let team = teams[teamIndex];
    if (team.contestants) {
      team.contestants.push({});
    } else {
      team.contestants = [{}];
    }
    this.setState({ teams });
  }
  onUpdateTeam = (index) => (e, { name, value }) => {
    let { teams } = this.state;
    let team = teams[index];
    team = { ...team, [name]: value };
    teams[index] = team;
    this.setState({ teams });
  }
  onUpdateTeamLeader = (index) => (e, { name, value }) => {
    let { teamLeaders } = this.state;
    teamLeaders[index] = { ...teamLeaders[index], [name]: value };
    this.setState({ teamLeaders });
    if (name == 'givenName' || name == 'surname') {
      let { givenName, surname } = this.state.teamLeaders[index];
      givenName = _.defaultTo(givenName, '');
      surname = _.defaultTo(surname, '');
      teamLeaders[index] = { ...teamLeaders[index], fullName: `${givenName} ${surname}` };
      this.setState({ teamLeaders });
    }
  }
  onUpdateObserver = (index) => (e, { name, value }) => {
    let { observers } = this.state;
    observers[index] = { ...observers[index], [name]: value };
    this.setState({ observers });
    if (name == 'givenName' || name == 'surname') {
      let { givenName, surname } = this.state.observers[index];
      givenName = _.defaultTo(givenName, '');
      surname = _.defaultTo(surname, '');
      observers[index] = { ...observers[index], fullName: `${givenName} ${surname}` };
      this.setState({ observers });
    }
  }
  onUpdateContestant = (teamIndex, contestantIndex) => (e, { name, value }) => {
    let { teams } = this.state;
    let team = teams[teamIndex];
    let { contestants } = team;
    contestants[contestantIndex] = { ...contestants[contestantIndex], [name]: value };
    team.contestants = contestants;
    teams[teamIndex] = team;
    this.setState({ teams });
    if (name == 'givenName' || name == 'surname') {
      let { givenName, surname } = this.state.teams[teamIndex].contestants[contestantIndex];
      givenName = _.defaultTo(givenName, '');
      surname = _.defaultTo(surname, '');
      contestants[contestantIndex] = { ...contestants[contestantIndex], fullName: `${givenName} ${surname}` };
      team.contestants = contestants;
      teams[teamIndex] = team;
      this.setState({ teams });
    }
  }
  onDeleteTeamLeader = (index) => () => {
    let { teamLeaders } = this.state;
    teamLeaders.splice(index, 1);
    this.setState({ teamLeaders });
  }
  onDeleteObserver = (index) => () => {
    let { observers } = this.state;
    observers.splice(index, 1);
    this.setState({ observers });
  }
  onDeleteContestant = (teamIndex, contestantIndex) => () => {
    let { teams } = this.state;
    let { contestants } = teams[teamIndex];
    contestants.splice(contestantIndex, 1);
    teams[teamIndex].contestants = contestants;
    this.setState({ teams });
  }

  render() {
    const { isLoading, country, numTeamLeaders, numContestants, numObservers, teamLeaders, teams, observers } = this.state;
    return (
      <div>
        <Dimmer active={isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        <Container>
          <Header as="h2">Country</Header>
          <Input fluid disabled value={country.name} />
          <Header as="h2">Team Summary</Header>
          <Form>
            <Form.Group width="equal">
              <Form.Field 
                control={Input} type="number" name="numTeamLeaders" label="Team Leaders"
                value={numTeamLeaders} onChange={this.onChange}
              />
              <Form.Field 
                control={Input} type="number" name="numContestants" label="Contestants"
                value={numContestants} onChange={this.onChange}
              />
              <Form.Field 
                control={Input} type="number" name="numObservers" label="Observers"
                value={numObservers} onChange={this.onChange}
              />
            </Form.Group>
          </Form>
          <Header as="h2">Participants</Header>
          <Header as="h3">Team Leaders</Header>
          {teamLeaders.map((person, index) => <TeamLeaderCard index={index} onChange={this.onUpdateTeamLeader(index)} onDelete={this.onDeleteTeamLeader(index)} {...person} />)}
          <Button icon="add" content="Add Team Leader" onClick={this.onAddTeamLeader} />
          <Header as="h3">Contestants</Header>
          <Button icon="add" content="Add Team" onClick={this.onAddTeam} />
          {teams.map((team, teamIndex) => (
            <div>
              <Header as="h4" style={{ marginTop: 20 }}>Team #{teamIndex + 1}</Header>
              <Input placeholder="Team name" name="name" value={team.name} onChange={this.onUpdateTeam(teamIndex)} style={{ display: 'block', marginBottom: 10 }} />
              {team.contestants && team.contestants.map((person, index) => (
                <ContestantCard index={index} onChange={this.onUpdateContestant(teamIndex, index)} onDelete={this.onDeleteContestant(teamIndex, index)} {...person} />
              ))}
              <Button
                icon="add" content={`Add Contestant to Team #${teamIndex + 1}`}
                onClick={() => this.onAddContestant(teamIndex)}
              />
            </div>
          ))}
          <Header as="h3">Observers</Header>
          {observers.map((person, index) => <ObserverCard index={index} onChange={this.onUpdateObserver(index)} onDelete={this.onDeleteObserver(index)} {...person} />)}
          <Button icon="add" content="Add Observer" onClick={this.onAddObserver} />
          <div style={{ marginTop: 20 }}>
            <Button primary icon="check" content="Save" style={{ float: 'right' }} onClick={this.onSave} />
          </div>
        </Container>
      </div>
    )
  }
}

EventRegistration.propTypes = propTypes;

export default EventRegistration;
