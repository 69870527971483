import React from 'react';
import ReactDOM from 'react-dom';

import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import firebase from 'firebase/app';
import firebaseui from 'firebaseui';
import 'semantic-ui-css/semantic.min.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';

const history = createBrowserHistory();
firebase.initializeApp({
  apiKey: 'AIzaSyDQJ8mKfT8No5oqPWhgv20gD5roU2DtyBM',
  authDomain: 'iol-registration.firebaseapp.com',
  databaseURL: 'https://iol-registration.firebaseio.com',
  projectId: 'iol-registration',
});
firebase.firestore().settings({
  timestampsInSnapshots: true,
});

const router = (
  <Router history={history}>
    <App />
  </Router>
);

ReactDOM.render(router, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
