import React, { Component } from 'react';
import firebase from 'firebase';
import { Link } from 'react-router-dom';
import { Container, Header, Segment } from 'semantic-ui-react';

const Event = props => {
  const { event } = props;
  return (
    <Link to={`/events/${event.id}`}>
      <Segment padded="very">
        <Header as="h1" content={event.title} />
      </Segment>
    </Link>
  );
}

class EventsContainer extends Component {
  state = {
    events: [],
  }

  async componentWillMount() {
    const snapshot = await firebase.firestore().collection("events").get();
    const events = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
    this.setState({ events });
  }

  render() {
    const { events } = this.state
    return (
      <Container>
        {events.map(event => <Event event={event} />)}
      </Container>
    )
  }
}

export default EventsContainer;
