import _ from 'lodash';
import React, { Component } from 'react';
import firebase from 'firebase';
import { Container, Button, Dimmer, Form, Header, Loader, Input, Dropdown, Select } from 'semantic-ui-react';

class Profile extends Component {
  state = {
    isLoading: true,
    user: {},
    countries: [],
  };

  constructor() {
    super();
    firebase.auth().onAuthStateChanged(this.onAuthStateChanged)
  }

  async componentWillMount() {
    const snapshot = await firebase.firestore().collection("countries").get();
    const countries = snapshot.docs.map(doc => ({ ...doc.data() }))
    countries.sort((c1, c2) => {
      const name1 = c1["text"]
      const name2 = c2["text"]
      if (name1 < name2) {
        return -1;
      }
      if (name2 > name1) {
        return 1;
      }
      return 0;
    })
    this.setState({ countries });
  }

  onAuthStateChanged = async (firebaseUser) => {
    if (firebaseUser == null) return;
    try {
      const { uid } = firebaseUser;
      let user = (await firebase.firestore().collection('users').doc(uid).get()).data();
      if (user == null) {
        user = {};
      }
      this.setState({ isLoading: false, user });
    } catch (error) {
      alert(error);
    }
  }

  onSave = async () => {
    const { uid } = firebase.auth().currentUser;
    const { user } = this.state;
    try {
      await firebase.firestore().collection("users").doc(uid).set(user);
      alert("Your profile was updated.");
    } catch (error) {
      alert(error);
    }
  }

  onChange = (e, { name, value }) => {
    let { user } = this.state;
    user = { ...user, [name]: value };
    this.setState({ user });
  }

  render() {
    const { isLoading, user, countries } = this.state;
    return (
      <Container>
        <Dimmer active={isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        <Container text>
          <Header as="h1">Profile</Header>
          <Form>
            <Form.Group>
              <Form.Field 
                control={Input} type="text" name="givenName" label="Given name"
                width={4} value={user.givenName} onChange={this.onChange}
              />
              <Form.Field
                control={Input} type="text" name="surname" label="Surname"
                width={4} value={user.surname} onChange={this.onChange}
              />
              <Form.Field
                control={Input} type="text" name="fullName" label="Displayed name"
                width={8} value={user.fullName} onChange={this.onChange}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                control={Select} fluid search selection name="nationality" label="Nationality"
                options={countries} value={user.nationality} onChange={this.onChange}
              />
              <Form.Field
                control={Input} type="email" name="email" label="Email"
                value={user.email} onChange={this.onChange}
              />
            </Form.Group>
            <Form.TextArea
              type="text" name="about" label="About" rows={5}
              value={user.about} onChange={this.onChange}
            />
            <Form.Field
              control={Input} type="text" name="quote" label="Favorite Quote"
              value={user.quote} onChange={this.onChange}
            />
          </Form>
          <div style={{ marginTop: 20 }}>
            <Button primary icon="check" content="Save" style={{ float: 'right' }} onClick={this.onSave} />
          </div>
        </Container>
      </Container>
    )
  }
}

export default Profile;
