import React from 'react';
import { Modal } from 'semantic-ui-react';
import firebase from 'firebase';
import firebaseui from 'firebaseui';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

const Login = () => {
  const uiConfig = {
    credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
    signInSuccessUrl: '/',
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
  };
  return (
    <Modal defaultOpen size="mini">
      <Modal.Content>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      </Modal.Content>
    </Modal>
  );
};

export default Login;
