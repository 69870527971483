import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container, Dropdown, Menu } from 'semantic-ui-react';
import firebase from 'firebase';

const propTypes = {
  pathname: PropTypes.string.isRequired,
};

const onLogout = () => firebase.auth().signOut();

class NavBar extends Component {
  state = {
    user: null,
  };

  constructor() {
    super();
    firebase.auth().onAuthStateChanged(user => this.setState({ user }));
  }

  render() {
    const { pathname } = this.props;
    const { user } = this.state;

    const isActive = path => pathname.includes(path);
    const AuthenticatedMenu = () => (
      <Menu.Menu position="right">
        <Dropdown item text="My Menu">
          <Dropdown.Menu>
            <Dropdown.Item text="Profile" as={Link} to="/profile/" />
            <Dropdown.Item text="Logout" onClick={onLogout} />
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    );
    const NotAuthenticatedMenu = () => (
      <Menu.Menu position="right">
        <Menu.Item name="login" as={Link} to="/login/" />
      </Menu.Menu>
    );

    return (
      <Menu fixed="top" size="large" secondary pointing style={{ backgroundColor: 'white' }}>
        <Container>
          <Menu.Item name="IOL Registration" as={Link} to="/" header />
          <Menu.Item name="Events" as={Link} to="/events/" active={isActive('events/')} />
          {user != null ? <AuthenticatedMenu /> : <NotAuthenticatedMenu />}
        </Container>
      </Menu>
    );
  }
}

NavBar.propTypes = propTypes;

export default NavBar;
